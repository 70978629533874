import React from "react";
import {StaticImage} from "gatsby-plugin-image";
import ContactForm from "../../components/contact-form";
import Layout from "../../components/layout";
import Seo from "../../components/seo"
import PortfolioFeed from "../../components/portfolio-feed";

const PortfolioHeader = () => (
  <section className="bg-dark py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-4">
          <div className="row">
            <div className="col-12">
              <h3 className="text-secondary-light">Client</h3>
              <p className="text-white">Vault Industries</p>
              <p className="text-white">Vault is a fake company name to protect our client’s identity.</p>
            </div>
            <div className="col-12 mt-0 mt-lg-5">
              <h3 className="text-secondary-light">Our Role</h3>
              <p className="text-white">UI Design
                <br></br>
                Logo / Identity Design
                <br></br>
                Zendesk Guide Implementation
                <br></br>
                Custom Portal Build
              </p>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-8">
          <h3 className="text-secondary-light">Summary</h3>
          <h2 className="text-white mb-0">
            729 solutions created a self service portal by stretching the limitations of zendesk guide to meet customer
            needs.
          </h2>
        </div>
      </div>
    </div>
  </section>
);

const PortfolioImage = () => (
  <section className="bg-light pt-6" style={{borderBottom: '10px solid #0d74c2'}}>
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div style={{width: '65%'}}>
            <StaticImage
              src="../../images/portfolio/vault/vault-header.png"
              alt="Vault"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
);

const ProjectGoals = () => (
  <section>
    <div className="container py-6">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Problem Statement</h2>
          <p>This client, one of the World’s Leading Foreign Exchange Platforms, needed a solution to pull data from
            their various 3rd party tools into a user portal so that their clients could access details about their
            accounts in one consolidated place.</p>
          <p>This particular client chose Zendesk Guide to be the single place for information to display, but the
            concept could be applied to a custom build as well. Zendesk Guide does not present this functionality out of
            the box – so 729 Solutions set out to customize it to accommodate the client’s needs.</p>
        </div>
        <div className="col-12 col-lg-6 mt-5 mt-lg-0">
          <h2>approach</h2>
          <p>The system we built is made up of two components – Portal and Sync that use HTML, CSS, Javascript, and
            Ruby. These two components are separate from each other. The Portal produces elements that are shown on the
            homepage of Zendesk Guide. This displays information about users invoices, documents, and roles. Each user
            is only shown the information that is relevant to them based on their role within the client’s organization.
            Users can review information, as well as make updates through this interface. Updates are stored within
            Zendesk as well as sent to the other systems.</p>
          <p>In order to make this happen, the Portal connects to Zoho and Quickbooks API, and pulls information into
            Zendesk to provide this information. This information is then correlated and displayed to the user, taking
            the user’s role into account for what can be displayed and what should not be displayed.</p>

          <p>The Sync pulls data from a separate Zoho instance, and syncs that data to the Client’s Zoho instance. The
            combined data from these two Zoho instances are then synced to Zendesk to create Accounts/Organizations and
            Contacts/Users every 24 hours.</p>
        </div>
      </div>
    </div>
  </section>
);


const DevelopmentLanguagesAndNeeds = () => (
  <section className="my-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="mb-4">
            <h2 className="pb-3 m-0">development languages used</h2>
            <p>JavaScript</p>
            <p>Ruby</p>
            <p>HTML 5</p>
            <p>CSS 3</p>
          </div>
          <div className="mb-4">
            <h2 className="pb-3 m-0">tools used</h2>
            <p>Zoho</p>
            <p>Zendesk</p>
            <p>Quickbooks</p>
          </div>
          <div className="mb-4">
            <h2 className="pb-3 m-0">framework used</h2>
            <p>Ruby on Rails</p>
          </div>
          <div className="mb-4">
            <h2 className="pb-3 m-0">Databases</h2>
            <p>MySQL</p>
          </div>
          <div className="mb-4">
            <h2 className="pb-3 m-0">key focus areas</h2>
            <p>Data Sync Between 3rd Party Tools</p>
            <p>Role Based User Capabilities</p>
            <p>Zendesk Guide Integration</p>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <StaticImage
            src="../../images/portfolio/vault/vault-main-topbar.png"
            alt="PureFlix"
          />
        </div>
      </div>
    </div>
  </section>
);

const ProjectForm = ({location}) => (
  <section className="bg-light py-6">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-6">
          <h2>Have a Project?</h2>
          <p className="pr-5">We'd love to hear about it!</p>
          <small className="text-muted pr-5">
            Email us at{" "}
            <a href="mailto:solicitations@729solutions.com">
              solicitations@729solutions.com
            </a>{" "}
            for solicitations regarding partnerships, linkbacks, or
            subcontracting opportunities.
          </small>
        </div>
        <div className="col-12 col-lg-6">
          <ContactForm showHowYouFindUs={false} buttonText="Submit" location={location}/>
        </div>
      </div>
    </div>
  </section>
);

const PartnerQuote = () => (
  <section className="bg-light py-5">
    <div className="container">
      <div className="row">
        <div className="col-12 col-lg-5 align-items-center">
          <div className="w-75">
            <StaticImage
              src="../images/partners/zendesk-implementation-premier-partner-badge.png"
              alt="Zendesk Implementation Premier Partner"
              width={400}
              layout="constrained"
            />
          </div>
        </div>
        <div className="col-12 col-lg-7">
          <h2 className="text-primary-alternative">729 is proud to be a Zendesk Implementation Premier Partner.</h2>
        </div>
      </div>
    </div>
  </section>
)


const Index = ({location}) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title="Vault"
        description="We created a self service portal for vault by stretching the limitations of Zendesk Guide to meet the customers needs."
      />
      <PortfolioHeader/>
      <PortfolioImage/>
      <ProjectGoals/>
      <PartnerQuote/>
      <DevelopmentLanguagesAndNeeds/>
      <ProjectForm location={location}/>
      <PortfolioFeed className="my-6"/>
    </Layout>
  );
};

export default Index;
